<template>
  <div>
    <!-- loading component -->
    <b-loading
      :is-full-page="false"
      v-model="loading"
      :can-cancel="false"
    />

    <!-- organization location form component -->
    <validation-observer
      ref="organizationLocationsForm"
      v-slot="{ invalid, handleSubmit }"
    >
      <form @submit.prevent="handleSubmit(saveLocation)">
        <validation-provider
          rules="required"
          name="name"
          v-slot="{ errors }"
        >
          <b-field
            :type="{ 'is-danger': errors[0] }"
            :message="errors"
            label="Name"
            class="is-primary is-required"
          >
            <b-input v-model="name" />
          </b-field>
        </validation-provider>

        <div class="columns is-vcentered">
          <div class="column">
            <validation-provider
              rules="required"
              name="timezone"
              v-slot="{ errors }"
            >
              <b-field
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                label="Time Zone"
                class="is-primary is-required"
              >
                <b-select
                  v-model="timezoneId"
                  expanded
                >
                  <option
                    v-for="timezone in timezones"
                    :key="timezone.id"
                    :value="timezone.id"
                  >
                    {{ timezone.value }}
                  </option>
                </b-select>
              </b-field>
            </validation-provider>
          </div>

          <div class="column">
            <validation-provider
              rules="required"
              name="is active"
              v-slot="{ errors }"
            >
              <b-field
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <b-switch
                  native-value="true"
                  type="is-success"
                  v-model="isActive"
                >
                  Active
                </b-switch>
              </b-field>
            </validation-provider>
          </div>
        </div>

        <b-field>
          <b-button
            type="is-success"
            icon-left="plus"
            tag="input"
            native-type="submit"
            value="add"
            :disabled="invalid"
          >
            Add
          </b-button>
        </b-field>
      </form>
    </validation-observer>

    <!-- organization location lists component -->
    <b-collapse
      class="is-spaced-top"
      v-for="(location, index) in organizationLocations"
      :key="index"
      :open="isOpen === index"
      @open="isOpen = index"
    >
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
      >
        <p class="card-header-title has-text-white">
          {{ location.name }}
        </p>
        <a class="card-header-icon">
          <b-icon
            :icon="props.open ? 'chevron-up' : 'chevron-down'"
            class="has-text-white"
            size="is-small"
          />
        </a>
      </div>

      <div class="card-content">
        <b-field
          label="Name"
          class="is-primary"
        >
          <b-input v-model="location.name" />
        </b-field>

        <div class="columns is-vcentered">
          <div class="column">
            <b-field
              label="Time Zone"
              class="is-primary"
            >
              <b-select
                v-model="location.timezoneId"
                expanded
              >
                <option
                  v-for="timezone in timezones"
                  :key="timezone.id"
                  :value="timezone.id"
                >
                  {{ timezone.value }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="column">
            <b-field>
              <b-switch
                type="is-success"
                v-model="location.isActive"
              >
                Active
              </b-switch>
            </b-field>
          </div>
        </div>

        <b-field>
          <div class="buttons">
            <b-button
              v-show="$route.name === 'EditOrganization'"
              icon-left="edit"
              type="is-info"
              @click="$emit('update-location', location)"
            >
              update
            </b-button>

            <b-button
              icon-left="trash"
              type="is-warning"
              @click="$emit('remove-location', location)"
            >
              remove
            </b-button>
          </div>
        </b-field>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {

  name: 'OrganizationsLocationsForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    timezones: {
      type: Array,
      required: true,
    },

    organizationLocations: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    isOpen: null,
  }),

  computed: {
    name: {
      get() {
        return this.$store.state.Organizations.location.name;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_LOCATION_NAME', value);
      },
    },

    timezoneId: {
      get() {
        return this.$store.state.Organizations.location.timezoneId;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_LOCATION_TIMEZONE_ID', value);
      },
    },

    isActive: {
      get() {
        return this.$store.state.Organizations.location.isActive;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_LOCATION_IS_ACTIVE', value);
      },
    },
  },

  methods: {
    saveLocation() {
      this.$refs.organizationLocationsForm.reset();
      this.$emit('save-location');
    },
  },

};
</script>

<style lang="css" scoped>
</style>
