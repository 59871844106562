<template>
  <form autocomplete="off">
    <validation-provider
      rules="required"
      name="name"
      v-slot="{ errors }"
    >
      <b-field
        horizontal
        label="Name"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-input v-model="name" />
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="Primary User"
      class="is-required"
    >
      <div class="columns">
        <div class="column is-half">
          <validation-provider
            rules="required"
            name="primary user"
            v-slot="{ errors }"
          >
            <b-field
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
            >
              <b-autocomplete
                v-model="primaryUser"
                :open-on-focus="true"
                :data="filteredUsersData"
                field="fullName"
                @select="option => selected = option"
              />
            </b-field>
          </validation-provider>
        </div>
      </div>
    </b-field>

    <b-field
      horizontal
      label="Time Zone"
      class="is-required"
    >
      <div class="columns">
        <div class="column is-half">
          <validation-provider
            rules="required"
            name="timezone"
            v-slot="{ errors }"
          >
            <b-field
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
            >
              <b-select
                v-model="timeZoneId"
                expanded
              >
                <option
                  v-for="timezone in timezones"
                  :key="timezone.id"
                  :value="timezone.id"
                >
                  {{ timezone.value }}
                </option>
              </b-select>
            </b-field>
          </validation-provider>
        </div>
      </div>
    </b-field>

    <validation-provider
      rules="required"
      name="street 1"
      v-slot="{ errors }"
    >
      <b-field
        horizontal
        label="Street 1"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-input v-model="street1" />
      </b-field>
    </validation-provider>

    <validation-provider
      rules=""
      name="street 2"
      v-slot="{ errors }"
    >
      <b-field
        horizontal
        label="Street 2"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <b-input v-model="street2" />
      </b-field>
    </validation-provider>

    <validation-provider
      rules=""
      name="street 3"
      v-slot="{ errors }"
    >
      <b-field
        horizontal
        label="Street 3"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <b-input v-model="street3" />
      </b-field>
    </validation-provider>

    <b-field horizontal>
      <div class="columns">
        <div class="column is-two-fifths">
          <validation-provider
            rules="required"
            name="state"
            v-slot="{ errors }"
          >
            <b-field
              label="State"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              class="is-required"
            >
              <b-autocomplete
                v-model="state"
                field="name"
                ref="stateAutocomplete"
                :data="filteredStatesData"
                :open-on-focus="true"
                @select="option => selected = option"
              />
            </b-field>
          </validation-provider>
        </div>

        <div class="column is-two-fifths">
          <validation-provider
            rules="required"
            name="city"
            v-slot="{ errors }"
            expanded
          >
            <b-field
              label="City"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              class="is-required"
            >
              <b-input v-model="city" />
            </b-field>
          </validation-provider>
        </div>

        <div class="column">
          <validation-provider
            rules="required|min:5"
            name="zip code"
            v-slot="{ errors }"
          >
            <b-field
              label="Zip Code"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              class="is-required"
            >
              <b-input v-model="zipCode" />
            </b-field>
          </validation-provider>
        </div>
      </div>
    </b-field>
  </form>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import states from '@/static/states.json';

export default {
  name: 'OrganizationForm',

  components: {
    ValidationProvider,
  },

  props: {
    users: {
      type: Array,
      required: true,
    },

    timezones: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    selected: null,
    states,
  }),

  computed: {
    name: {
      get() {
        return this.$store.state.Organizations.organization.name;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_ORGANIZATION_NAME', value);
      },
    },

    primaryUser: {
      get() {
        return this.$store.state.Organizations.organization.primaryUser;
      },

      set(value) {
        if (this.selected) {
          this.$store.commit('Organizations/SET_ORGANIZATION_PRIMARY_USER_ID', this.selected.id);
        }
        return this.$store.commit('Organizations/SET_ORGANIZATION_PRIMARY_USER', value);
      },
    },

    timeZoneId: {
      get() {
        return this.$store.state.Organizations.organization.timeZoneId;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_ORGANIZATION_TIME_ZONE_ID', value);
      },
    },

    street1: {
      get() {
        return this.$store.state.Organizations.organization.street1;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_ORGANIZATION_ADDRESS_STREET_1', value);
      },
    },

    street2: {
      get() {
        return this.$store.state.Organizations.organization.street2;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_ORGANIZATION_ADDRESS_STREET_2', value);
      },
    },

    street3: {
      get() {
        return this.$store.state.Organizations.organization.street3;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_ORGANIZATION_ADDRESS_STREET_3', value);
      },
    },

    state: {
      get() {
        return this.$store.state.Organizations.organization.state;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_ORGANIZATION_ADDRESS_STATE', value);
      },
    },

    city: {
      get() {
        return this.$store.state.Organizations.organization.city;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_ORGANIZATION_ADDRESS_CITY', value);
      },
    },

    zipCode: {
      get() {
        return this.$store.state.Organizations.organization.zipCode;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_ORGANIZATION_ADDRESS_ZIP_CODE', value);
      },
    },

    filteredStatesData() {
      return this.states.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.state.toLowerCase()) >= 0);
    },

    filteredUsersData() {
      return this.users.filter((option) => option.fullName
        .toLowerCase()
        .indexOf(this.primaryUser.toLowerCase()) >= 0);
    },
  },

  destroyed() {
    return this.$store.commit('Organizations/CLEAR_ORGANIZATION');
  },
};
</script>

<style lang="css" scoped>
</style>
