<template>
  <div>
    <table-header
      @export-pdf="exportPdf"
      @export-xlsx="exportXlsx"
    />

    <b-table
      :loading="loading"
      :data="organizations"
      checkable
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      narrowed
      striped
    >
      <b-table-column
        sortable
        searchable
        label="Name"
        field="name"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <b-input
            v-model="props.filters['name']"
            placeholder="Search..."
            icon="search"
          />
        </template>
        <template v-slot="props">
          <span class="has-text-weight-bold">
            {{ props.row.name | capitalize }}
          </span>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Time Zone"
        custom-key="timeZone"
      >
        {{ props.row.defaultTimezone.value.match(/\((.*?)\)/)[1] }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Primary User"
        field="primaryUser"
      >
        <router-link
          :to="{ name: 'EditUser', params: { userId: props.row.primaryUser.id } }"
          class="has-text-info has-text-weight-semibold"
        >
          {{ $options.filters.capitalize(props.row.primaryUser.firstName) }}
          {{ $options.filters.capitalize(props.row.primaryUser.lastName) }}
        </router-link>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Locations"
        custom-key="locations"
      >
        <span
          v-for="location in props.row.locations"
          :key="location.id"
        >
          {{ location.name }},
        </span>
      </b-table-column>

      <b-table-column
        v-slot="props"
        custom-key="actions"
      >
        <div class="buttons">
          <b-tooltip
            type="is-info"
            label="Edit"
            position="is-bottom"
          >
            <b-button
              type="is-text"
              size="is-medium"
              class="has-text-grey"
              icon-right="edit"
              icon-pack="fas"
              tag="router-link"
              :to="{ name: 'EditOrganization', params: { organizationId: props.row.id } }"
            />
          </b-tooltip>

          <b-tooltip
            type="is-info"
            label="Delete"
            position="is-bottom"
          >
            <b-button
              type="is-text"
              size="is-medium"
              class="has-text-grey"
              icon-right="trash"
              icon-pack="fas"
              @click="deleteOrganization(props.row.id)"
            />
          </b-tooltip>
        </div>
      </b-table-column>

      <template slot="empty">
        <empty-table table-name="organizations" />
      </template>

      <template slot="footer">
        <div class="columns is-mobile">
          <div class="column has-background-primary">
            <span class="has-text-white is-uppercase">
              Records {{ perPage }} of {{ total }} records
            </span>
          </div>

          <div class="column is-narrow has-background-info">
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-bottom-left"
            >
              <b-button
                size="is-small"
                type="is-info"
                slot="trigger"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(i, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="i"
                >
                  {{ i }}
                </b-dropdown-item>
                <hr
                  :key="i"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { EmptyTable, TableHeader } from '@/components/Shared';

export default {

  name: 'OrganizationsTable',

  components: {
    EmptyTable,
    TableHeader,
  },

  data: () => ({
    columns: [
      { title: 'Name', dataKey: 'name' },
      { title: 'City', dataKey: 'city' },
      { title: 'State', dataKey: 'state' },
      { title: 'Owner', dataKey: 'primaryUser', displayProperty: 'username' },
      { title: 'Created On', dataKey: 'createdAt' },
    ],
    checkedRows: [],
    total: 0,
    page: 1,
    perPage: 10,
    pages: [10, 25, 50, 100],
  }),

  computed: {
    ...mapGetters({
      organizations: 'Organizations/getOrganizations',
      loading: 'Organizations/getLoading',
    }),

    exportData() {
      return this.checkedRows.length >= 1 ? this.checkedRows : this.organizations;
    },
  },

  mounted() {
    this.fetchOrganizations();
  },

  methods: {
    async fetchOrganizations() {
      try {
        const response = await this.$store.dispatch('Organizations/fetchOrganizations', {
          page: this.page - 1,
          perPage: this.perPage,
        });
        let currentTotal = response.totalElements;
        if (response.totalElements / this.perPage > response.totalElements) {
          currentTotal = this.perPage * response.totalElements;
        }
        this.total = currentTotal;
        return this.$buefy.notification.open({
          message: 'Successfully retrieved the organizations',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: 'Error retrieving the organizations',
          type: 'is-warning',
          position: 'is-bottom-right',
        });
      }
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @params { Number } - next page number
     */
    onPageChange(page) {
      this.page = page;
      this.fetchOrganizations();
    },

    /**
     * deleteOrganization
     *
     * Delete an organization
     * @params {Number} organization's id
     */
    deleteOrganization(organizationId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting organization',
        message: 'Are you sure you want to <b>delete</b> your organization? This action cannot be undone.',
        confirmText: 'Delete Organization',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Organizations/deleteOrganization', organizationId);
            return this.$buefy.notification.open({
              message: 'Successfully delete the organization',
              type: 'is-success',
              position: 'is-bottom-right',
            });
          } catch (error) {
            return this.$buefy.notification.open({
              message: error.message,
              type: 'is-warning',
              position: 'is-bottom-right',
            });
          }
        },
      });
    },

    /**
     * exportPdf
     *
     * export organizations data as PDF
     */
    exportPdf() {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      doc.autoTable({
        columns: this.columns,
        body: this.exportData,
        didParseCell: (data) => {
          if (data.column.raw.displayProperty) {
            const prop = data.column.raw.displayProperty;
            const text = data.cell.raw[prop];
            if (text && text.length > 0) {
              // eslint-disable-next-line no-param-reassign
              data.cell.text = text;
            }
          }
        },
      });
      doc.save('organizations.pdf');
    },

    /**
     * exportXlsx
     *
     * export organizations data as XLSX
     */
    exportXlsx() {
      const blob = new Blob(
        [Papa.unparse(this.exportData)],
        { type: 'text/csv;charset=utf-8;' },
      );
      fileDownload(blob, 'organizations.csv');
    },
  },
};
</script>

<style lang="css" scoped>

</style>
